import Axios from '.';

/**
 * * Import Sale Data (https://sime-darby-dev.appicidea.com/doc/v2#post-/v1/sales/importSaleData)
 */

export const importSaleData = async (fd: FormData) => {
  const { data } = await Axios.post<any>('/sales/importSaleData', fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 600000,
  });
  return data;
};
